import type { LightFilterItem } from '@workspace/api';

import type { FilterItemTransformer } from '~modules/filters/types';

import { ifValue } from './ifValue';

export const replaceByFilterKey = <FilterKey extends string>(
    filterKey: FilterKey,
    getItem: (value: string) => LightFilterItem | null,
): FilterItemTransformer<FilterKey> => {
    return ifValue(filterKey, ({ value, lightFilter }) => {
        const item = getItem(value);

        if (item) {
            lightFilter.push(item);
        }
    });
};
