import type { SerializationConfig } from '~modules/filters/types';

import { keepStringValue } from './keepStringValue';
import { roundValue } from './roundValue';

const { deserialize, serialize } = keepStringValue;

export const numberStringValue = {
    deserialize,
    serialize: v => {
        const value = serialize(v);

        if (!value) {
            return value;
        }
        const valueAsNumber = Number(v);

        if (isNaN(valueAsNumber)) {
            return value;
        }

        return String(roundValue(valueAsNumber));
    },
} satisfies SerializationConfig<string | null | undefined>;
