export function createDecimalProcessor(fallback: string) {
    return (value: string) => {
        const valueAsNumber = Number(value);

        if (isNaN(valueAsNumber)) {
            return fallback;
        }

        return value;
    };
}
