import type { AddDataComparisonMetaData, FilterItemTransformer } from '~modules/filters/types';

import { addDataGtEq } from './addDataGtEq';
import { addDataLtEq } from './addDataLtEq';

export const addValueBetween = <FilterKey extends string>(
    property: string,
    filterKeyFrom: FilterKey,
    filterKeyTo: FilterKey,
    meta: AddDataComparisonMetaData,
): FilterItemTransformer<FilterKey> => {
    const from = addDataLtEq(property, filterKeyTo, meta);
    const to = addDataGtEq(property, filterKeyFrom, meta);

    return (where, lightFilter) => {
        from(where, lightFilter);
        to(where, lightFilter);
    };
};
