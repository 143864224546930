import { LightFilterItemOperator } from '@workspace/api';

import type { FilterItemTransformer } from '~modules/filters/types';
import { isApiTrueIsh } from '~utils/apiVerity';

export const addAndMappedBooleans = <FilterKey extends string>(
    property: string,
    valueMap: Record<FilterKey, string>,
): FilterItemTransformer<FilterKey> => {
    return (where, lightFilter) => {
        const appliedEntries = Object.entries(valueMap).filter(([filterKey]) => {
            const { value } = where[filterKey] ?? {};

            return isApiTrueIsh(value);
        });

        if (!appliedEntries.length) {
            return;
        }

        const operator = appliedEntries.length === 1 ? LightFilterItemOperator.EQUALS : LightFilterItemOperator.IN;

        const value = appliedEntries.map(([, v]) => v).join(';');

        lightFilter.push({ operator, property, value });
    };
};
