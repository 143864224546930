export enum LiabilityInvoicesBooleanFilterKey {
    Typed = 'Typed',
    Confirmed = 'Confirmed',
    Financed = 'Financed',
    CompletedWithAccession = 'CompletedWithAccession',
    Cancelled = 'Cancelled',
    CancelledOutOfLimit = 'CancelledOutOfLimit',
}

export const liabilityInvoicesBooleanFilters = {
    [LiabilityInvoicesBooleanFilterKey.Typed]: ' ',
    [LiabilityInvoicesBooleanFilterKey.Confirmed]: 'A',
    [LiabilityInvoicesBooleanFilterKey.Financed]: 'N',
    [LiabilityInvoicesBooleanFilterKey.CompletedWithAccession]: 'U',
    [LiabilityInvoicesBooleanFilterKey.Cancelled]: 'X',
    [LiabilityInvoicesBooleanFilterKey.CancelledOutOfLimit]: 'S',
} as const satisfies Readonly<Record<LiabilityInvoicesBooleanFilterKey, string>>;

export type LiabilityInvoicesBooleanFilters = typeof liabilityInvoicesBooleanFilters;
