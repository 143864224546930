import { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { clearQueryCache } from '~modules/suppliers/utils';

export function useClearQueryCacheOnMount() {
    const qc = useQueryClient();
    const mountedRef = useRef(false);

    useEffect(() => {
        if (mountedRef.current) {
            return;
        }
        mountedRef.current = true;
        clearQueryCache(qc);
    }, [qc]);
}
