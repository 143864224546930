import type { GridPaginationModel, GridSortItem } from '@mui/x-data-grid';

import type { RowRange } from '@workspace/api';

import type { EntityOrderByItem, UnknownEntity } from '../types';

export const createOrderByItem = <Entity extends UnknownEntity>({
    field,
    sort,
}: GridSortItem): EntityOrderByItem<Entity> => ({
    dataKey: field,
    ascending: sort === 'asc',
});

export function createOrderByItems<Entity extends UnknownEntity>(
    filterOrder: EntityOrderByItem<Entity>[] = [],
    sorter: GridSortItem | null,
) {
    if (sorter) {
        return [createOrderByItem(sorter)];
    }

    return [...filterOrder];
}

export const createRowRange = ({ page, pageSize }: GridPaginationModel): Required<RowRange> =>
    ({
        firstRow: page * pageSize,
        rowCount: pageSize,
    }) as const;
