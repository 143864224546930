import type { QueryClient } from '@tanstack/react-query';

import { QueryKey } from '@workspace/api';

const QUERY_KEY_WHITELIST: ReadonlyArray<any> = [
    QueryKey.VERSION_DISPLAY,
    // This is only used in SelectSupplierPage, no need to remove query with available suppliers
    QueryKey.SUPPLIER_BIND,
] as const;

export function clearQueryCache(qc: QueryClient) {
    qc.removeQueries({ predicate: q => !QUERY_KEY_WHITELIST.includes(q.queryKey[0]) });
}
