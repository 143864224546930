export enum DiscountInvoicesBooleanFilterKey {
    Typed = 'Typed',
    Proposal = 'Proposal',
    ConfirmedForAssignment = 'ConfirmedForAssignment',
    Assigned = 'Assigned',
    Completed = 'Completed',
    CompletedWithaClaim = 'CompletedWithaClaim',
    WithdrawalFromAssignment = 'WithdrawalFromAssignment',
    Cancelled = 'Cancelled',
}

/**
 * @url https://192.168.103.169:9443/diagrams/invoices/discount-invoice-filter-form.svg
 */
export const discountInvoicesBooleanFilters = {
    [DiscountInvoicesBooleanFilterKey.Typed]: ' ',
    [DiscountInvoicesBooleanFilterKey.Proposal]: 'B',
    [DiscountInvoicesBooleanFilterKey.ConfirmedForAssignment]: 'C',
    [DiscountInvoicesBooleanFilterKey.Assigned]: 'D',
    [DiscountInvoicesBooleanFilterKey.WithdrawalFromAssignment]: 'Y',
    [DiscountInvoicesBooleanFilterKey.Completed]: 'Z',
    [DiscountInvoicesBooleanFilterKey.CompletedWithaClaim]: 'W',
    [DiscountInvoicesBooleanFilterKey.Cancelled]: 'X',
} as const satisfies Readonly<Record<DiscountInvoicesBooleanFilterKey, string>>;

export type DiscountInvoicesBooleanFilters = typeof discountInvoicesBooleanFilters;
