import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { QueryKey, userSupplierBind_findByLightDataRequest, type EfcvUserSupplierBindModel } from '@workspace/api';
import { useAuthUserInfo } from '@workspace/auth';
import { Table } from '@workspace/ui';

import { authRoutes, routes } from '~constants';
import { usePaginationModel } from '~hooks';
import { QueryError, QueryLoader } from '~modules/api/components';
import { createRowRange } from '~modules/filters/utils';
import { useShowMessagePopup } from '~modules/message-popup/hooks';
import { LogActions } from '~modules/session-log/constants';
import { useLogActionOnShow } from '~modules/session-log/hooks';
import { generatePath } from '~utils/generatePath';

import { useClearQueryCacheOnMount } from '../../hooks/useClearQueryCacheOnMount';

export const SelectSupplierPage = () => {
    useClearQueryCacheOnMount();
    useLogActionOnShow(LogActions.SYSTEM_SUPPLIERCUSTOMER_CHOOSE);
    const { formatMessage } = useIntl();
    const userInfo = useAuthUserInfo();
    const showMessage = useShowMessagePopup();
    const [paginationModel, setPaginationModel, isUserSettingsLoaded] = usePaginationModel();

    const { push } = useRouter();

    const handleSelect = useCallback(
        (doId: string) => {
            void push(generatePath(authRoutes.supplierDetail, { doId }));
        },
        [push],
    );

    const result = useQuery({
        queryKey: [QueryKey.SUPPLIER_BIND, userInfo?.loginName, paginationModel],
        queryFn: async () => {
            const { data } = await userSupplierBind_findByLightDataRequest({
                filter: [{ operator: 'EQUALS', property: 'username', value: userInfo?.loginName }],
                rowRange: createRowRange(paginationModel),
            });

            return data!;
        },
        enabled: isUserSettingsLoaded,
    });

    const { result: data, rowCount = 0 } = result.data || {};

    useEffect(() => {
        if (data?.length === 1) {
            handleSelect(data[0].doId!);

            return;
        }
        // just in case, this is already checked in PasswordLoginForm.tsx
        if (data?.length === 0) {
            (async () => {
                await showMessage('error', { id: 'selectSupplier.noSubjectFound' });
                await push(routes.login);
            })();
        }
    }, [data, showMessage, handleSelect, push]);

    return (
        <>
            <Typography variant='h1'>
                <FormattedMessage id='selectSupplier.title' />
            </Typography>
            <QueryError result={result} message={{ id: 'general.error' }}>
                <QueryLoader result={result}>
                    <Table<EfcvUserSupplierBindModel>
                        rowCount={rowCount}
                        rows={data ?? []}
                        columns={[
                            {
                                field: 'doId',
                                minWidth: 100,
                                headerName: formatMessage({ id: 'selectSupplier.columns.id' }),
                                sortable: false,
                                flex: 1,
                            },
                            {
                                field: 'doName',
                                minWidth: 100,
                                headerName: formatMessage({ id: 'selectSupplier.columns.name' }),
                                sortable: false,
                                flex: 1,
                            },
                            {
                                field: 'doIdNumber',
                                minWidth: 100,
                                headerName: formatMessage({ id: 'selectSupplier.columns.doIdNumber' }),
                                sortable: false,
                                flex: 1,
                            },
                            {
                                field: 'doAddress',
                                minWidth: 100,
                                headerName: formatMessage({ id: 'selectSupplier.columns.address' }),
                                sortable: false,
                                flex: 1,
                            },
                        ]}
                        onRowClick={async values => {
                            handleSelect(values.row.doId);
                        }}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                    />
                </QueryLoader>
            </QueryError>
        </>
    );
};
