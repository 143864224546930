import type { LightFilterItemOperator } from '@workspace/api';

import { isApiTrueIsh } from '~utils/apiVerity';

import { ifValue } from './ifValue';

export const addAndIfTrue = <FilterKey extends string>(
    property: string,
    operator: LightFilterItemOperator,
    filterKey: FilterKey,
    lightFilterValue: string,
) => {
    return ifValue(filterKey, ({ value, lightFilter }) => {
        if (isApiTrueIsh(value)) {
            lightFilter.push({ operator, property, value: lightFilterValue });
        }
    });
};
