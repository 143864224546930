export enum IssuedInvoicesBooleanFilterKey {
    General = 'General',
    IssuedFeeInvoice = 'IssuedFeeInvoice',
    PreFinancingOverpayment = 'PreFinancingOverpayment',
    CommissionInvoice = 'CommissionInvoice',
    SupplierPenaltyInvoice = 'SupplierPenaltyInvoice',
    InterestInvoice = 'InterestInvoice',
    RepurchasingReceivable = 'RepurchasingReceivable',
    LiabilityAccession = 'LiabilityAccession',
    PreFinancingOverpaymentWithParticipation = 'PreFinancingOverpaymentWithParticipation',
    ReceivableFromWithdrawal = 'ReceivableFromWithdrawal',
}

/**
 * @url https://192.168.103.169:9443/diagrams/invoices/issued-invoice-filter-form.svg
 */
export const issuedInvoicesBooleanFilters = {
    [IssuedInvoicesBooleanFilterKey.General]: 'F',
    [IssuedInvoicesBooleanFilterKey.IssuedFeeInvoice]: 'K',
    [IssuedInvoicesBooleanFilterKey.PreFinancingOverpayment]: 'M',
    [IssuedInvoicesBooleanFilterKey.CommissionInvoice]: 'P;G',
    [IssuedInvoicesBooleanFilterKey.SupplierPenaltyInvoice]: 'Q',
    [IssuedInvoicesBooleanFilterKey.InterestInvoice]: 'U',
    [IssuedInvoicesBooleanFilterKey.RepurchasingReceivable]: 'V',
    [IssuedInvoicesBooleanFilterKey.LiabilityAccession]: 'H',
    [IssuedInvoicesBooleanFilterKey.PreFinancingOverpaymentWithParticipation]: 'C',
    [IssuedInvoicesBooleanFilterKey.ReceivableFromWithdrawal]: 'I',
} as const satisfies Readonly<Record<IssuedInvoicesBooleanFilterKey, string>>;

export type IssuedInvoicesBooleanFilters = typeof issuedInvoicesBooleanFilters;
