import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { clearQueryCache } from '~modules/suppliers/utils';

import { lastDoIdAtom } from '../atoms/lastDoId';

export function useClearQueryCache(doId: string | null) {
    const [lastDoId, setLastDoId] = useAtom(lastDoIdAtom);
    const qc = useQueryClient();

    useEffect(() => {
        if (doId && lastDoId !== doId) {
            setLastDoId(doId);
            clearQueryCache(qc);
        }
    }, [lastDoId, doId, qc, setLastDoId]);
}
