export enum FilterCode {
    // Invoices
    PURCHASED_INVOICES = 'EFCWEBAPP_PURCHASEDINVOICE',
    ISSUED_INVOICES = 'EFCWEBAPP_ISSUEDINVOICE',
    NONFINANCED_INVOICES = 'EFCWEBAPP_NONFINANCEDINVOICE',
    DUE_INVOICES = 'EFCWEBAPP_DUEINVOICE',
    LIABILITY_INVOICES = 'EFCWEBAPP_LIABILITYINVOICE',
    DISCOUNT_INVOICES = 'EFCWEBAPP_DISCOUNTINVOICE',

    // Batches
    BATCH_PURCHASED_INVOICES = 'EFCWEBAPP_INFILEPURCHASED',
    BATCH_PURCHASED_INVOICES_FOR_CEDING = 'EFCWEBAPP_INFILEPURCHASEDFORCEDING',
    BATCH_DISCOUNT_INVOICES = 'EFCWEBAPP_INFILEDISCOUNT',
    BATCH_LIABILITY_INVOICES = 'EFCWEBAPP_INFILELIABILITY',
    BATCH_REVERSE_PURCHASED_INVOICES = 'EFCWEBAPP_INFILEPURCHASEDREVERSE',
    BATCH_REVERSE_DISCOUNT_INVOICES = 'EFCWEBAPP_INFILEDISCOUNTREVERSE',

    SUPPLIER_LIMITS = 'EFCWEBAPP_SUPPLIERLIMIT',
    SUPPLIER_PRODUCT_LIMITS = 'EFCWEBAPP_SUPPLIERPRODUCTLIMIT',
    SUPPLIER_CONTRACT_LIMITS = 'EFCWEBAPP_SUPPLIERCONTRACTLIMIT',
    SUPPLIER_CUSTOMER_LIMITS = 'EFCWEBAPP_SUPPLIERCUSTOMERLIMIT',

    DAT_EXPORTS = 'EFCWEBAPP_OUTFILE',
    PDF_EXPORTS = 'EFCWEBAPP_OUTFILEPDF',

    LOGIN_HISTORY = 'EFCWEBAPP_LOGINHISTORY',
    OPEN_INVOICES = 'EFCWEBAPP_OPENINVOICE',

    PAYMENTS = 'EFCWEBAPP_PAYMENT',

    // Customer (supplier) modal
    CUSTOMER_PURCHASED_INVOICE = 'EFCWEBAPP_SUPPLIERCUSTOMERLIMITMODALPURCHASED',
    CUSTOMER_DISCOUNT_INVOICE = 'EFCWEBAPP_SUPPLIERCUSTOMERLIMITMODALDISCOUNT',
    CUSTOMER_LIABILITY_INVOICE = 'EFCWEBAPP_SUPPLIERCUSTOMERLIMITTMODALLIABILITY',
    CUSTOMER_DISCOUNT_INVOICE_REVERSED = 'EFCWEBAPP_SUPPLIERCUSTOMERLIMITMODALDISCOUNTREVERSE',
    CUSTOMER_PURCHASED_INVOICE_REVERSED = 'EFCWEBAPP_SUPPLIERCUSTOMERLIMITMODALPURCHASEDREVERSE',
}

export type BatchFilterCode =
    | FilterCode.BATCH_PURCHASED_INVOICES
    | FilterCode.BATCH_PURCHASED_INVOICES_FOR_CEDING
    | FilterCode.BATCH_DISCOUNT_INVOICES
    | FilterCode.BATCH_LIABILITY_INVOICES
    | FilterCode.BATCH_REVERSE_PURCHASED_INVOICES
    | FilterCode.BATCH_REVERSE_DISCOUNT_INVOICES;

export type CustomerModalFilterCode =
    | FilterCode.CUSTOMER_PURCHASED_INVOICE
    | FilterCode.CUSTOMER_DISCOUNT_INVOICE
    | FilterCode.CUSTOMER_LIABILITY_INVOICE
    | FilterCode.CUSTOMER_DISCOUNT_INVOICE_REVERSED
    | FilterCode.CUSTOMER_PURCHASED_INVOICE_REVERSED;
