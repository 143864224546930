import { MULTIPLE_VALUES_SEPARATOR } from '~modules/filters/constants';
import type { SerializationConfig } from '~modules/filters/types';

export const arrayValues: SerializationConfig<string[] | null> = {
    deserialize: v => {
        if (typeof v === 'string') {
            return v.split(MULTIPLE_VALUES_SEPARATOR);
        }

        return [];
    },
    serialize: v => (Array.isArray(v) && v.length ? v.join(MULTIPLE_VALUES_SEPARATOR) : null),
};
