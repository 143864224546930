import type { SerializationConfig } from '~modules/filters/types';

import { roundValue } from './roundValue';

export const roundedNumberOutValue: SerializationConfig<number | null | undefined> = {
    // @ts-expect-error form expects string values to exist
    deserialize: v => {
        if (typeof v !== 'string') {
            return null;
        }

        const numberValue = Number(v);

        if (isNaN(numberValue)) {
            return null;
        }

        return v;
    },
    serialize: v => {
        if (typeof v === 'number') {
            return String(roundValue(v));
        }

        return null;
    },
};
