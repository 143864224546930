import type { LightFilterItemOperator } from '@workspace/api';

import { ifValue } from './ifValue';

export const addAndStringOperator = (operator: LightFilterItemOperator) => {
    return <FilterKey extends string>(property: string, filterKey: FilterKey) => {
        return ifValue(filterKey, ({ value, lightFilter }) => {
            const valueWithWildcards = value.replaceAll('*', '%');

            lightFilter.push({ value: valueWithWildcards, operator, property });
        });
    };
};
