export enum PurchaseInvoicesBooleanFilterKey {
    Typed = 'Typed',
    Confirmed = 'Confirmed',
    FrontFinanced = 'FrontFinanced',
    BackFinanced = 'BackFinanced',
    Completed = 'Completed',
    BackCession = 'BackCession',
    CompletedWithaClaim = 'CompletedWithaClaim',
    InsurancePayment = 'InsurancePayment',
    GuaranteePayment = 'GuaranteePayment',
    Cancelled = 'Cancelled',
}

/**
 * @url https://192.168.103.169:9443/diagrams/invoices/purchased-invoice-filter-form.svg
 */
export const purchasedInvoicesBooleanFilters = {
    [PurchaseInvoicesBooleanFilterKey.Typed]: ' ',
    [PurchaseInvoicesBooleanFilterKey.Confirmed]: 'A',
    [PurchaseInvoicesBooleanFilterKey.FrontFinanced]: 'P',
    [PurchaseInvoicesBooleanFilterKey.BackFinanced]: 'T',
    [PurchaseInvoicesBooleanFilterKey.Completed]: 'Z',
    [PurchaseInvoicesBooleanFilterKey.BackCession]: 'V',
    [PurchaseInvoicesBooleanFilterKey.CompletedWithaClaim]: 'W',
    [PurchaseInvoicesBooleanFilterKey.InsurancePayment]: 'L',
    [PurchaseInvoicesBooleanFilterKey.GuaranteePayment]: 'G',
    [PurchaseInvoicesBooleanFilterKey.Cancelled]: 'X',
} as const satisfies Readonly<Record<keyof typeof PurchaseInvoicesBooleanFilterKey, string>>;

export type PurchasedInvoicesBooleanFilters = typeof purchasedInvoicesBooleanFilters;
