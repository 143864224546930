import { APIStringBoolean } from '@workspace/api/types';

import type { SerializationConfig } from '~modules/filters/types';

function parseEnumValue(v: string | null | undefined) {
    if (APIStringBoolean.TRUE.toLowerCase() === v?.toLowerCase()) {
        return APIStringBoolean.TRUE;
    }

    if (APIStringBoolean.FALSE.toLowerCase() === v?.toLowerCase()) {
        return APIStringBoolean.FALSE;
    }

    return APIStringBoolean.BOTH;
}

export const radioValue: SerializationConfig<APIStringBoolean> = {
    serialize: v => {
        const enumValue = parseEnumValue(v);

        if (enumValue === APIStringBoolean.BOTH) {
            return null;
        }

        return enumValue.toUpperCase();
    },
    deserialize: parseEnumValue,
};
