import { LightFilterItemOperator } from '@workspace/api';

import { ifValue } from './ifValue';

export function addAndLikeListWithNull<FilterKey extends string>(property: string, filterKey: FilterKey) {
    return ifValue(filterKey, ({ value, lightFilter }) => {
        if (value !== 'null' && value !== 'X') {
            lightFilter.push({ operator: LightFilterItemOperator.LIKE, value, property });
        }
    });
}
