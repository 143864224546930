import { APIStringBoolean } from '@workspace/api/types';

type Value = string | null | undefined;

export const isApiTrue = (value: Value) => value?.toLowerCase() === APIStringBoolean.TRUE;

const trueIshValues: ReadonlyArray<Value> = ['true', APIStringBoolean.TRUE, '1'] as const;

export const isApiTrueIsh = (value: Value) => trueIshValues.includes(value);

export const isApiFalse = (value: Value) => value?.toLowerCase() === APIStringBoolean.FALSE;
