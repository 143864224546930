import { LightFilterItemOperator } from '@workspace/api';

import type { AddDataComparisonMetaData } from '~modules/filters/types';

import { createDecimalProcessor } from './createDecimalProcessor';
import { ifValue } from './ifValue';

const processDecimal = createDecimalProcessor(String(Number.MAX_SAFE_INTEGER));

export const addDataGtEq = <FilterKey extends string>(
    property: string,
    filterKey: FilterKey,
    meta: AddDataComparisonMetaData,
) => {
    return ifValue(filterKey, ({ value, lightFilter }) => {
        const processedValue = meta.type === 'number' ? processDecimal(value) : value;

        lightFilter.push({ value: processedValue, operator: LightFilterItemOperator.GREATER_OR_EQUALS, property });
    });
};
