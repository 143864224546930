import type { SerializationConfig } from '~modules/filters/types';

export const dateValue: SerializationConfig<Date | null> = {
    deserialize: value => {
        if (!value) {
            return null;
        }

        const msSinceEpoch = Number(value);

        if (isNaN(msSinceEpoch)) {
            return null;
        }

        const date = new Date(msSinceEpoch);

        if (isNaN(date.getTime())) {
            return null;
        }

        return date;
    },
    serialize: value => {
        if (!value || isNaN(value.getTime())) {
            return null;
        }

        return String(value.getTime());
    },
};
