import type {
    EntityFilterEntry,
    FilterItemTransformer,
    FilterTransformer,
    UnknownEntity,
} from '~modules/filters/types';

export function createFilterTransformer<FilterKey extends string>(
    itemTransformers: FilterItemTransformer<FilterKey>[],
): FilterTransformer<FilterKey> {
    return where => {
        return itemTransformers.reduce((acc, transformer) => {
            transformer(where, acc);

            return acc;
        }, new Array<EntityFilterEntry<UnknownEntity>>());
    };
}
