import { FilterCode, FilterType } from '../constants';
import type { EntityFilterModel, UnknownEntity } from '../types';

export function findOrCreateCurrentSelection<Entity extends UnknownEntity>(
    filters: EntityFilterModel<Entity>[],
    filterCode: FilterCode,
) {
    return (
        filters.find(f => f.type === FilterType.CURRENT_SELECTION) ?? {
            type: FilterType.CURRENT_SELECTION,
            order: [],
            where: {},
            name: crypto.randomUUID(),
            viewCode: filterCode,
            filterId: undefined,
        }
    );
}
