import { useQuery } from '@tanstack/react-query';

import { dataService_setDoid, QueryKey, userSupplierBind_findByLightDataRequest } from '@workspace/api';
import { useAuthUserInfo } from '@workspace/auth';
import { Loader, LoaderContainer } from '@workspace/ui';

import { QueryParam } from '~constants';
import { useRouterQuery } from '~hooks';
import { QueryError, QueryLoader } from '~modules/api/components';
import { SelectedSupplierContext } from '~modules/suppliers/context';

import { useClearQueryCache } from '../../hooks/useClearQueryCache';

export interface SelectedSupplierContextProps {
    children: React.ReactNode;
}

export const SelectedSupplierProvider = ({ children }: SelectedSupplierContextProps) => {
    const query = useRouterQuery([QueryParam.DoId] as const);
    const doId = query?.doId;

    useClearQueryCache(doId ?? null);

    const userInfo = useAuthUserInfo();

    const result = useQuery({
        queryKey: ['dataService_setDoid', doId],
        queryFn: () => dataService_setDoid({ request: doId }),
        enabled: Boolean(query),
        staleTime: Infinity,
        gcTime: Infinity,
    });

    const { data: selectedSupplier } = useQuery({
        queryKey: [QueryKey.SUPPLIER_BIND, userInfo?.loginName, doId],
        queryFn: async () => {
            const { data } = await userSupplierBind_findByLightDataRequest({
                filter: [
                    { operator: 'EQUALS', property: 'username', value: userInfo?.loginName },
                    { operator: 'EQUALS', property: 'doId', value: doId },
                ],
            });

            return data!;
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });

    if (!selectedSupplier?.result) {
        return (
            <LoaderContainer height='fullBox'>
                <Loader />
            </LoaderContainer>
        );
    }

    const selectedSupplierData = selectedSupplier.result[0];

    return (
        <QueryError
            result={result}
            message={{
                id: 'general.error',
            }}
        >
            <QueryLoader result={result}>
                <SelectedSupplierContext.Provider value={{ ...selectedSupplierData, doId }}>
                    {children}
                </SelectedSupplierContext.Provider>
            </QueryLoader>
        </QueryError>
    );
};
