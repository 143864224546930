import type { LightFilterItem } from '@workspace/api';

import type { FilterItemTransformer } from '~modules/filters/types';

export interface IfValueParams {
    lightFilter: LightFilterItem[];
    value: string;
}

export type IfValueCallback = (params: IfValueParams) => void;

export const ifValue = <FilterKey extends string>(
    filterKey: FilterKey,
    cb: IfValueCallback,
): FilterItemTransformer<FilterKey> => {
    return (where, lightFilter) => {
        const item = where[filterKey];

        if (!item) return;

        const { value } = item;

        if (typeof value === 'string') {
            cb({ lightFilter, value });
        }
    };
};
